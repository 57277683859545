import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Button, Modal, Accordion} from 'react-bootstrap';
import { useNavigate  } from "react-router-dom";
import axios from "axios";
import Header from '../components/Header';
import Back from '../components/back';
import UserService from '../services/UserService';
import HashUtil from '../services/HashUtil';

const serverConfig = require("../config/server.js");

const ApplicationDetailsEdit = () => {

  const navigate = useNavigate();

  const [mToken, setMToken] = useState(sessionStorage.getItem('mToken'));

  var url = window.location.href;
  var applicationID = url.substring(url.indexOf('?')+1);
  console.log('applicationID', applicationID);

  const [appData, setAppData] = useState('');
  const [appDataEmailid, setAppDataEmailid] = useState('');
  const [timeSpentOnPortal, setTimeSpentOnPortal] = useState('');
  const [docValData, setDocValData] = useState([]);
  const [exceptionList, setExceptionList] = useState([]);

  const [acceptDisabled, setAcceptDisabled] = useState(true);
  const [approveExceptionDisabled, setApproveExceptionDisabled] = useState(true);
  const [applicationReadOnly, setApplicationReadOnly] = useState(true);

  const [isLoading, setIsLoading] = useState(true);

  const [inputs, setInputs] = useState([]);

  /* Passport */
  const [showPassport, setShowPassport] = useState(false);
  const handleClosePassport = () => setShowPassport(false);
  const handleShowPassport = () => setShowPassport(true);
  /* Supplementary Files */
  const [supplementaryFile, setSupplementaryFile] = useState("");
  const [showSupplementaryFile, setShowSupplementaryFile] = useState(false);
  const handleCloseSupplementaryFile = () => setShowSupplementaryFile(false);
  const handleShowSupplementaryFile = (filedata) => {
    setSupplementaryFile(filedata);
    setShowSupplementaryFile(true);
  }
  /* Secondary Doc */
  const [showSecondaryDoc, setShowSecondaryDoc] = useState(false);
  const handleCloseSecondaryDoc = () => setShowSecondaryDoc(false);
  const handleShowSecondaryDoc = () => setShowSecondaryDoc(true);
  /* Selfie Picture */
  const [showSelfiePicture, setShowSelfiePicture] = useState(false);
  const handleCloseSelfiePicture = () => setShowSelfiePicture(false);
  const handleShowSelfiePicture = () => setShowSelfiePicture(true);
  /* FTIN ID */
  const [showFtinId, setShowFtinId] = useState(false);
  const handleCloseFtinId = () => setShowFtinId(false);
  const handleShowFtinId = () => setShowFtinId(true);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  const formatDateTime = (datestr) => {
    var d = new Date(datestr);
    var dd = String(d.getDate()).padStart(2, '0');
    var mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = d.getFullYear();
    var hh = String(d.getHours()).padStart(2, '0');
    var min = String(d.getMinutes()).padStart(2, '0');
    var ss = String(d.getSeconds()).padStart(2, '0');
    return mm + '/' + dd + '/' + yyyy + ', ' + hh + ':' + min + ':' + ss;
  }

  const [exceptionInputs, setExceptionInputs] = useState([]);
  const handleChange = (event) => {
    //console.log('handle change');
    const exceptionId = event.target.id;
    //console.log(exceptionId);
    if(event.target.type === 'checkbox'){
      if (event.target.checked) {
        if(event.target.parentElement.classList.contains('N')){
          event.target.parentElement.querySelector('.reasons').classList.add('d-block');
          event.target.parentElement.querySelector('.reasons').classList.remove('d-none');
          event.target.parentElement.querySelector('.reasons textarea').required = true;
        }
        setExceptionInputs(values => ([...values, {exception_id:exceptionId}]));
        //setExceptionInputs(values => ([...values, exceptionId]));
        
      } else {
        if(event.target.parentElement.classList.contains('N')){
          event.target.parentElement.querySelector('.reasons').classList.add('d-none');
          event.target.parentElement.querySelector('.reasons').classList.remove('d-block');
          event.target.parentElement.querySelector('.reasons textarea').required = false;
          event.target.parentElement.querySelector('.reasons textarea').value = '';
        }
        setExceptionInputs(exceptionInputs.filter(e => e.exception_id !== exceptionId));
        //setExceptionInputs(exceptionInputs.filter(e => e !== exceptionId));
        
      }
      console.log(exceptionInputs);
    } else if(event.target.type === 'textarea'){
      console.log(exceptionInputs);
      console.log(event.target.name, event.target.value);
      const newArr = exceptionInputs.map(ei => {
        return ei.exception_id === event.target.name ? {...ei, exception_reason: event.target.value} : ei
      });
      console.log(newArr);
      setExceptionInputs(newArr);
      console.log(exceptionInputs);
    }
  }
  const handleApproveExceptions = (e) => {
    e.preventDefault();
    console.log('handle approve exceptions', exceptionInputs);
    //console.log(Object.keys(exceptionInputs).length);
    if(Object.keys(exceptionInputs).length > 0) {
      axios.post(serverConfig.api_base_url + "application_exception_approve",{token:mToken, exception_list:exceptionInputs, approve:1})
      .then(response=>{
        console.log("application_exception_approve response:", response);
        if(response.data.status === 200){
          //window.location.reload();
          setExceptionInputs([]);
          var x = document.getElementsByClassName("checkbox");
          let i;
          for(i=0; i<=x.length; i++) {
            x[i].checked = false;
          }
        } else if(response.data.status === 301){
          handleLogout();
        }
        //window.location.reload();
        /* var x = document.getElementsByClassName("checkbox");
        let i;
        for(i=0; i<=x.length; i++) {
          x[i].checked = false;
        }
        setExceptionInputs([]); */
      })
      .catch(err=>console.log("error is",err));
    }
  }
  const handleDisapproveExceptions = (e) => {
    e.preventDefault();
    console.log('handle disapprove exceptions', exceptionInputs);
    //console.log(Object.keys(exceptionInputs).length);
    if(Object.keys(exceptionInputs).length > 0) {
      axios.post(serverConfig.api_base_url + "application_exception_approve",{token:mToken, exception_list:exceptionInputs, approve:0})
      .then(response=>{
        console.log("Disapprove application_exception_approve response:", response);
        if(response.data.status === 200){
          //window.location.reload();
          setExceptionInputs([]);
          var x = document.getElementsByClassName("checkbox");
          let i;
          for(i=0; i<=x.length; i++) {
            x[i].checked = false;
          }
        } else if(response.data.status === 301){
          handleLogout();
        }
        //window.location.reload();
        /* var x = document.getElementsByClassName("checkbox");
        let i;
        for(i=0; i<=x.length; i++) {
          x[i].checked = false;
        }
        setExceptionInputs([]); */
      })
      .catch(err=>console.log("error is",err));
    }
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('mToken'));
    //let emailid = UserService.getUsername();
    //console.log('email', emailid);
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('mToken', response.data.token);
        setMToken(response.data.token);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const getAppData = () => {
    axios.post(serverConfig.api_base_url + "application_data_get",{token:mToken, app_id:applicationID})
    .then(response=>{
      setIsLoading(false);
      console.log(mToken);
      console.log("application_data_get response:", response);
      console.log("application_data_get response:", response.data.output_data);
      console.log(response.data.status);
      if(response.data.status === 200){
        console.log('status 200');
        setAppDataEmailid(response.data.output_data.app_email_id);
        //console.log("application_data_get response:", JSON.parse(response.data.output_data).app_data);
        setAppData(response.data.output_data.app_data);
        setAppData((stateAppData) => {
          if(stateAppData !== null) {
            /* captureSelfiePassport */
            if(stateAppData.captureSelfiePassport){
              if(stateAppData.captureSelfiePassport !== null &&
      'FAIL' !== stateAppData.captureSelfiePassport.Status) {
                console.log(JSON.parse(stateAppData.captureSelfiePassport).FormDetails);
                setDocValData(HashUtil.copyObjectSubKeyVals(
                  JSON.parse(stateAppData.captureSelfiePassport).FormDetails.Identity_Validation_and_Face_Matching.IDmission_Image_Processing.ID_Image_Processing, '#text', {
                  name: 'Name_Doc',
                  gender: 'Gender',
                  address: 'Address',
                  country: 'Country',
                  MRZValid: 'MRZValid',
                  MRZ_Data: 'MRZ_Data',
                  MRZ_Name: 'MRZ_Name',
                  Issue_Date: 'Issue_Date',
                  MRZ_Gender: 'MRZ_Gender',
                  Nationality: 'Nationality',
                  AddressLine1: 'AddressLine1',
                  AddressLine2: 'AddressLine2',
                  MRZIDExpired: 'MRZIDExpired',
                  MRZ_Detected: 'MRZ_Detected',
                  MRZ_IDNumber: 'MRZ_IDNumber',
                  PlaceofIssue: 'PlaceofIssue',
                  Date_of_Birth: 'Date_of_Birth',
                  IssuingCountry: 'IssuingCountry',
                  MRZ_ExpiryDate: 'MRZ_ExpiryDate',
                  Place_of_Birth: 'Place_of_Birth',
                  Expiration_Date: 'Expiration_Date',
                  MRZDataExpected: 'MRZDataExpected',
                  MRZ_DateofBirth: 'MRZ_DateofBirth',
                  MRZ_Nationality: 'MRZ_Nationality',
                  MRZErrorMessages: 'MRZErrorMessages',
                  Issuing_Authority: 'Issuing_Authority',
                  MRZ_IssuingCountry: 'MRZ_IssuingCountry',
                  MRZDataExpectedOnBack: 'MRZDataExpectedOnBack',
                  MRZDataExpectedOnFront: 'MRZDataExpectedOnFront'
                } ));
              }
            }
            if(stateAppData.controlProcessStartTime && stateAppData.controlProcessFinishTime){
              let finish_date = new Date(stateAppData.controlProcessFinishTime);
              let start_date = new Date(stateAppData.controlProcessStartTime);
              // finding the difference in total seconds between two dates
              let second_diff = (finish_date.getTime() - start_date.getTime()) / 1000;
              // showing the relative timestamp.
              if (second_diff < 60) {
                console.log("difference is of " + second_diff + " seconds.");
                setTimeSpentOnPortal(second_diff.toFixed(2) + " seconds.");
              } else if (second_diff < 3600) {
                console.log("difference is of " + second_diff / 60 + " minutes.");
                setTimeSpentOnPortal((second_diff / 60).toFixed(2) + " minutes.");
              } else if (second_diff < 86400) {
                console.log("difference is of " + second_diff / 3600 + " hours.");
                setTimeSpentOnPortal((second_diff / 3600).toFixed(2) + " hours.");
              } else if (second_diff < 2620800) {
                console.log("difference is of " + second_diff / 86400 + " days.");
                setTimeSpentOnPortal((second_diff / 86400).toFixed(2) + " days.");
              } else if (second_diff < 31449600) {
                console.log("difference is of " + second_diff / 2620800 + " months.");
                setTimeSpentOnPortal((second_diff / 2620800).toFixed(2) + " months.");
              } else {
                console.log("difference is of " + second_diff / 31449600 + " years.");
                setTimeSpentOnPortal((second_diff / 31449600).toFixed(2) + " years.");
              }
            }
          }
          return stateAppData;
        });
        if(response.data.output_data.current_status === "re-invite sent"){
          console.log();
          setApplicationReadOnly(true);
        } else {
          setApplicationReadOnly(false);
        }
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(sessionStorage.getItem('mToken'));
    if(sessionStorage.getItem('mToken') === null){
      getToken();
    }
  }, []);

  useEffect(() => {
    if(mToken !== null && mToken !== undefined){
      
      /* user_check_privilege - approve_exception */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:mToken, privilege:'approve_exception'})
      .then(response=>{
        console.log("user_check_privilege response:", response.data.is_privileged);
        if(response.data.is_privileged === 0){
          setApproveExceptionDisabled(true);
        } else if(response.data.is_privileged === 1){
          setApproveExceptionDisabled(false);
        }
      })
      .catch(err=>console.log("error is",err));

      /* application_data_get */
      console.log('mToken', mToken);
      getAppData();
      console.log(appData);
    }
  }, [mToken]);

  useEffect(() => {
    console.log(exceptionInputs);
    //disable Accept button if any of the validations is not passed and user does not have approve_application rights
    if(mToken !== null && mToken !== undefined){
      /* application_exception_get API */
      axios.post(serverConfig.api_base_url + "application_exception_get",{token:mToken, application_id:applicationID})
      .then(response=>{
        console.log("application_exception_get response:", response);
        console.log("application_exception_get response:", response.data.output_data);
        console.log("application_exception_get response:", response.data.status);
        if(response.data.status === 200){
          console.log('status 200');
          if (response.data.output_data !== null && response.data.output_data !== undefined)
          setExceptionList(response.data.output_data);
          setExceptionList((stateExceptionList) => {
            //disable Accept button if any of the validations is not passed and user does not have approve_application rights
            if (stateExceptionList.filter(e => e.passed === 'N').length > 0) {
              setAcceptDisabled(true);
            } else {
                /* user_check_privilege - approve_application */
                axios.post(serverConfig.api_base_url + "user_check_privilege",{
                        token:mToken,
                        privilege:'approve_application'
                }).then(response=>{
                        console.log("user_check_privilege response:", response.data.is_privileged);
                        if(response.data.is_privileged === 0){
                                setAcceptDisabled(true);
                        } else if(response.data.is_privileged === 1){
                                setAcceptDisabled(false);
                        }
                })
                .catch(err=>console.log("error is",err));
            }
            return stateExceptionList;
          })
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [mToken, exceptionInputs]);

  const handleDataChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //setInputs(values => ([...values, {entity_id:name,data:value}]));
    //setInputs(values => ([{...values, entity_id:name, data:value}]))
    /* setInputs(values => {
      console.log(values, {...values});
      return (
        [{
          ...values,
          [name]: value
        }]
      )
    }
    )
    setInputs({
        ...inputs,
        entity_id:name,
        data:value
    }) */
    //setInputs(values => ({...values, entity_id:name}))
    //setInputs(values => ({...inputs, entity_id:name, data:value}))
    var newInputs = inputs;
    var matched = 0;
    for(var i = 0; i < newInputs.length && !matched; ++i) {
      if (newInputs[i]['entity_id'] == name) {
        console.log('Found entity_id ' + name + ' at position ' + i + ' new value: ' + value);
        newInputs[i]['data'] = value;
        matched = 1;
      }
    }

    if (!matched) {
      setInputs([...inputs, {'entity_id': name, 'data': value}]);
    } else {
      setInputs(newInputs);
    }
    console.log(inputs);
  }

  const handleSaveData = () => {
    console.log(inputs);
    axios.post(serverConfig.api_base_url + "application_data_set",{token:mToken, customer_id:applicationID, items: inputs})
    .then(response=>{
      console.log("application_data_set response:", response);
      if(response.data.status === 200){
        navigate('/application-details?'+applicationID);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleUpload = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    //console.log(value);
    var extension = value.split('.').pop();
    //console.log(extension);

    if(extension=='png' || extension=='jpg' || extension=='pdf'){
      setIsLoading(true);
      console.log("File type matched");
      var file = document.querySelector('#'+event.target.id).files[0];
      console.log(file.size);
      let fileSizeInMB = file.size  / 1048576;
      console.log(fileSizeInMB);

      if(file !== undefined) {
        let filename = file.name;
        var fileData;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          fileData = reader.result;
          if(fileSizeInMB <= 10){
            axios.post(serverConfig.api_base_url + "application_file_set",{token:mToken, customer_id:applicationID, entity_id:name, file_name: filename, file_data: fileData})
            .then(response=>{
              console.log('set_file response', response);
              if(response.data.status === 200) {
                setIsLoading(false);
                console.log('success');
                getAppData();
              } else if(response.data.status === 301){
                handleLogout();
              } else {
                alert(response.data.message);
                setIsLoading(false);
                document.getElementsByName(name)[0].value = '';
              }
            })
            .catch(err=>console.log("error is",err));
          } else {
            setIsLoading(false);
            handleShowErrorPopup('File size exceeds maximum limit of 10MB. Please try again.');
            event.target.value = '';
          }
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
      }
    } else {
      alert("Only png, jpg, pdf files are allowed");
      event.target.value = null;
    }
  }

  const [errorPopupText, setErrorPopupText] = useState(null);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false);
    setErrorPopupText(null);
  }
  const handleShowErrorPopup = (error_text) => {
    setErrorPopupText(error_text);
    setShowErrorPopup(true);
  }
  
  return (
    <>
      <Header />
      <main className='application-details'>
        {isLoading && (<div id="loading"></div>)}
        <section>
          <Container fluid>
            <div className='page-title'>
              <Back />
              <h1>New Account Onboarding</h1>
              <Button variant="primary" onClick={handleSaveData} className='ms-auto' disabled={applicationReadOnly}>Save</Button>
            </div>
          </Container>
        </section>
        <section className='bg-gray'>
          <Container fluid>
            <Row>
              <Col>
                <div className='label'>First Name</div>
                <div className='value'><input type="text" className='form-data' name="3" defaultValue={appData.firstName} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Middle Name</div>
                <div className='value'><input type="text" className='form-data' name="4" defaultValue={appData.middleName} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Last Name</div>
                <div className='value'><input type="text" className='form-data' name="5" defaultValue={appData.lastName} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Email Address</div>
                <div className='value'><input type="text" className='form-data' defaultValue={appDataEmailid} readOnly /></div>
              </Col>
              <Col>
                <div className='label'>Mobile Phone Number</div>
                <div className='value'><input type="text" className='form-data' name="6" defaultValue={appData.countryCode} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='value'><input type="text" className='form-data' name="7" defaultValue={appData.mobileNumber} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Date of Birth</div>
                <div className='value'><input type="text" className='form-data' name="8" defaultValue={appData.dateOfBirth} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Country of Birth</div>
                <div className='value'><input type="text" className='form-data' name="9" defaultValue={appData.countryOfBirth} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Mother's Maiden Name</div>
                <div className='value'><input type="text" className='form-data' name="10" defaultValue={appData.mothersMaidenName} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Citizenship</div>
                <div className='value'><input type="text" className='form-data' name="11" defaultValue={appData.citizenship} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row>
              <Col className='bdr-right'>
                <div className='label'><b>Permanent Address</b></div>
                <div className='label'>Address Line 1</div>
                <div className='value'><input type="text" className='form-data' name="13" defaultValue={appData.addressLine1} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Address Line 2</div>
                <div className='value'><input type="text" className='form-data' name="14" defaultValue={appData.addressLine2} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Country</div>
                <div className='value'><input type="text" className='form-data' name="12" defaultValue={appData.country} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>State / Province / Area</div>
                <div className='value'><input type="text" className='form-data' name="15" defaultValue={appData.state} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>City</div>
                <div className='value'><input type="text" className='form-data' name="16" defaultValue={appData.city} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Zipcode / Postal Code</div>
                <div className='value'><input type="text" className='form-data' name="17" defaultValue={appData.zipcode} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col className='bdr-right'>
                <div className='label'><b>Mailing Address</b></div>
                <div className='label'>Address Line 1</div>
                <div className='value'><input type="text" className='form-data' name="19" defaultValue={appData.addressLine1MailingAddress} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Address Line 2</div>
                <div className='value'><input type="text" className='form-data' name="20" defaultValue={appData.addressLine2MailingAddress} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Country</div>
                <div className='value'><input type="text" className='form-data' name="18" defaultValue={appData.countryMailingAddress} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>State / Province / Area</div>
                <div className='value'><input type="text" className='form-data' name="21" defaultValue={appData.stateMailingAddress} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>City</div>
                <div className='value'><input type="text" className='form-data' name="22" defaultValue={appData.cityMailingAddress} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Zipcode / Postal Code</div>
                <div className='value'><input type="text" className='form-data' name="23" defaultValue={appData.zipcodeMailingAddress} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'><b>US Address</b></div>
                <div className='label'>Address Line 1</div>
                <div className='value'><input type="text" className='form-data' name="25" defaultValue={appData.addressLine1US} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Address Line 2</div>
                <div className='value'><input type="text" className='form-data' name="26" defaultValue={appData.addressLine2US} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Country</div>
                <div className='value'><input type="text" className='form-data' name="24" defaultValue={appData.countryUS} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>State / Province / Area</div>
                <div className='value'><input type="text" className='form-data' name="27" defaultValue={appData.stateUS} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>City</div>
                <div className='value'><input type="text" className='form-data' name="28" defaultValue={appData.cityUS} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Zipcode / Postal Code</div>
                <div className='value'><input type="text" className='form-data' name="29" defaultValue={appData.zipcodeUS} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <h2>Validations</h2>
            <form onSubmit={handleApproveExceptions}>
              <fieldset disabled={applicationReadOnly}>
                <ul className='validation-check mb-3'>
                  {exceptionList &&
                    exceptionList.map((item, index) => {
                      var isDisabled;
                      if(item.passed === 'Y'){
                        isDisabled = true
                      }
                      return (
                      <li key={index} className={item.passed}>
                        <input type="checkbox" className='checkbox' id={item.exception_id} name={item.exception} value={item.description} disabled={isDisabled} onChange={handleChange} />
                        <div>
                          <label htmlFor={item.exception_id}>{item.description}</label>
                          {item.reason && <><br/><b>Reason:</b> {item.reason}</>}
                          {item.passed === 'N' && <div className='reasons d-none'><b>Reason:</b><span className='txt-red'>*</span> <textarea name={item.exception_id} onChange={handleChange} cols="100"></textarea></div>}
                        </div>
                      </li>
                      )})
                  }
                </ul>
                <Button variant="primary" className='btn-manual-override' /* onClick={handleApproveExceptions} */ disabled={approveExceptionDisabled} type="submit">Manual Override</Button>
                <Button variant="transparent" className='ms-3' onClick={handleDisapproveExceptions} disabled={approveExceptionDisabled}>Remove Override</Button>
              </fieldset>
            </form>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row className='align-items-center mb-2'>
              <Col xs="auto"><img src={require('./../assets/images/passport-icon.png')} alt="Passport Icon" /></Col>
              <Col xs="auto">
                <div className='label'>Passport Details</div>
                <div className='value'><span className='value-txt-small'>File uploaded on {formatDateTime(appData.passportImgTimestamp)}</span></div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='d-flex align-items-center'>
                  <img src={'data:image/jpeg;base64,'+appData.passportImg} height="80" alt="Image" className='pp-img' />
                  <span className='ms-3 view' onClick={handleShowPassport}>Click to view</span>
                  <Modal className='show-picture show-pp-picture' show={showPassport} onHide={handleClosePassport} backdrop="static"keyboard={false} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <img src={'data:image/jpeg;base64,'+appData.passportImg} alt="Image" className='img-fluid' />
                    </Modal.Body>
                  </Modal>
                </div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className="position-relative d-inline-block">
                  <label htmlFor="supplementaryPassport" className='upload upload-default'>
                    <span className="txt-upload">Upload File</span>
                  </label>
                  <input id="supplementaryPassport" name="114" type="file" accept=".png, .jpg, .pdf" onChange={handleUpload} disabled={applicationReadOnly} />
                </div>
              </Col>
              {appData.supplementaryPassport && <Col>
                <img src={appData.supplementaryPassport} height="80" alt="Image" />
                <span className='ms-3 view' onClick={() => handleShowSupplementaryFile(appData.supplementaryPassport)}>Click to view</span>
              </Col>}
              <Modal className='show-picture' show={showSupplementaryFile} onHide={handleCloseSupplementaryFile} backdrop="static"keyboard={false} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <img src={supplementaryFile} alt="Image" className='img-fluid' />
                </Modal.Body>
              </Modal>
            </Row>
            <Row className='mb-2'>
              <Col><span className='label'>Name:</span> {docValData.name}</Col>
              <Col><span className='label'>Gender:</span> {docValData.gender}</Col>
              <Col><span className='label'>Nationality:</span> {docValData.Nationality}</Col>
              <Col><span className='label'>Date_of_Birth:</span> {docValData.Date_of_Birth}</Col>
              <Col><span className='label'>Place_of_Birth:</span> {docValData.Place_of_Birth}</Col>
            </Row>
            <Row className='mb-4'>
              <Col><span className='label'>Issue_Date:</span> {docValData.Issue_Date}</Col>
              <Col><span className='label'>IssuingCountry:</span> {docValData.IssuingCountry}</Col>
              <Col><span className='label'>Expiration_Date:</span> {docValData.Expiration_Date}</Col>
              <Col><span className='label'>Issuing_Authority:</span> {docValData.Issuing_Authority}</Col>
              <Col><span className='label'>PlaceofIssue:</span> {docValData.PlaceofIssue}</Col>
            </Row>
            <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>MRZ Data</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col><span className='label'>MRZ_Data:</span> <span dangerouslySetInnerHTML={{__html: docValData.MRZ_Data}} /></Col>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>MRZ_Name:</span> {docValData.MRZ_Name}</Col>
                    <Col><span className='label'>MRZ_Gender:</span> {docValData.MRZ_Gender}</Col>
                    <Col><span className='label'>MRZ_IDNumber:</span> {docValData.MRZ_IDNumber}</Col>
                    <Col><span className='label'>MRZ_ExpiryDate:</span> {docValData.MRZ_ExpiryDate}</Col>
                    <Col><span className='label'>MRZ_DateofBirth:</span> {docValData.MRZ_DateofBirth}</Col>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>MRZ_Nationality:</span> {docValData.MRZ_Nationality}</Col>
                    <Col><span className='label'>MRZ_IssuingCountry:</span> {docValData.MRZ_IssuingCountry}</Col>
                    <Col><span className='label'>MRZ_Detected:</span> {docValData.MRZ_Detected}</Col>
                    <Col><span className='label'>MRZValid:</span> {docValData.MRZValid}</Col>
                    <Col><span className='label'>MRZIDExpired:</span> {docValData.MRZIDExpired}</Col>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>MRZDataExpected:</span> {docValData.MRZDataExpected}</Col>
                    <Col><span className='label'>MRZDataExpectedOnBack:</span> {docValData.MRZDataExpectedOnBack}</Col>
                    <Col><span className='label'>MRZDataExpectedOnFront:</span> {docValData.MRZDataExpectedOnFront}</Col>
                    <Col><span className='label'>MRZErrorMessages:</span> {docValData.MRZErrorMessages}</Col>
                    <Col></Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <h6 className='mt-4'>Manually entered information:</h6>
            <Row>
              <Col><span className='label'>Passport ID: </span> {appData.passportNumber}</Col>
              <Col><span className='label'>Issue Date:</span> {appData.IssuanceDate}</Col>
              <Col><span className='label'>Expiry Date:</span> {appData.expirationDate}</Col>
              <Col><span className='label'>Issuing Country:</span> {appData.countryOfIssuance}</Col>
              <Col></Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs="auto"><img src={require('./../assets/images/secondary-doc-icon.png')} alt="Secondary Document Icon" /></Col>
              <Col xs="auto">
                <div className='label'>Secondary Document Details</div>
                <div className='value'><span className='value-txt-small'>File uploaded on {formatDateTime(appData.secondaryDocImgTimestamp)}</span></div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='d-flex align-items-center'>
                  <img src={'data:image/jpeg;base64,'+appData.secondaryDocImg} height="80" alt="Image" />
                  <span className='ms-3 view' onClick={handleShowSecondaryDoc}>Click to view</span>
                  <Modal className='show-picture' show={showSecondaryDoc} onHide={handleCloseSecondaryDoc} backdrop="static"keyboard={false} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <img src={'data:image/jpeg;base64,'+appData.secondaryDocImg} alt="Image" className='img-fluid' />
                    </Modal.Body>
                  </Modal>
                </div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className="position-relative d-inline-block">
                  <label htmlFor="supplementarySecondaryDoc" className='upload upload-default'>
                    <span className="txt-upload">Upload File</span>
                  </label>
                  <input id="supplementarySecondaryDoc" name="115" type="file" accept=".png, .jpg, .pdf" onChange={handleUpload} disabled={applicationReadOnly} />
                </div>
              </Col>
              {appData.supplementarySecondaryDoc && <Col>
                <img src={appData.supplementarySecondaryDoc} height="80" alt="Image" />
                <span className='ms-3 view' onClick={() => handleShowSupplementaryFile(appData.supplementarySecondaryDoc)}>Click to view</span>
              </Col>}
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs="auto"><img src={require('./../assets/images/selfie-icon.png')} alt="Selfie Icon" /></Col>
              <Col xs="auto">
                <div className='label'>Selfie Picture</div>
                <div className='value'><span className='value-txt-small'>File uploaded on {formatDateTime(appData.selfieImgTimestamp)}</span></div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='d-flex align-items-center'>
                  <img src={'data:image/jpeg;base64,'+appData.selfieImg} height="80" alt="Image" />
                  <span className='ms-3 view' onClick={handleShowSelfiePicture}>Click to view</span>
                  <Modal className='show-picture' show={showSelfiePicture} onHide={handleCloseSelfiePicture} backdrop="static"keyboard={false} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <img src={'data:image/jpeg;base64,'+appData.selfieImg} alt="Image" className='img-fluid' />
                    </Modal.Body>
                  </Modal>
                </div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className="position-relative d-inline-block">
                  <label htmlFor="supplementarySelfie" className='upload upload-default'>
                    <span className="txt-upload">Upload File</span>
                  </label>
                  <input id="supplementarySelfie" name="116" type="file" accept=".png, .jpg, .pdf" onChange={handleUpload} disabled={applicationReadOnly} />
                </div>
              </Col>
              {appData.supplementarySelfie && <Col>
                <img src={appData.supplementarySelfie} height="80" alt="Image" />
                <span className='ms-3 view' onClick={() => handleShowSupplementaryFile(appData.supplementarySelfie)}>Click to view</span>
              </Col>}
            </Row>
          </Container>
        </section>
        <section className='bg-gray'>
          <Container fluid>
            <div className='label'><img src={require('./../assets/images/instructions-icon.png')} alt="Passport Icon" className='me-3' /> W8-BEN Details</div>
            <Row>
              <Col>
                <div className='label'>U.S. Taxpayer Identification Number (SSN or ITIN)</div>
                <div className='value'><input type="text" className='form-data' name="79" defaultValue={appData.w8ben_USTaxPayerId} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Foreign Tax Identification Number</div>
                <div className='value'><input type="text" className='form-data' name="81" defaultValue={appData.w8ben_foreignTaxPayer} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>FTIN (Foreign Tax Identification Number) not legally required</div>
                <div className='value'><input type="text" className='form-data' name="82" defaultValue={appData.w8ben_FtinNotReq} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Reference number(s)</div>
                <div className='value'><input type="text" className='form-data' name="83" defaultValue={appData.w8ben_referenceNumber} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bg-gray'>
          <Container fluid>
            <h6>Special rates and conditions: (if applicable)</h6>
            <Row>
              <Col>
                <div className='label'>Article and paragraph</div>
                <div className='value'><input type="text" className='form-data' name="93" defaultValue={appData.w8ben_article} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>% rate</div>
                <div className='value'><input type="text" className='form-data' name="94" defaultValue={appData.w8ben_rate} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Type of Income</div>
                <div className='value'><input type="text" className='form-data' name="95" defaultValue={appData.w8ben_typeOfIncome} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Additional Conditions</div>
                <div className='value'><input type="text" className='form-data' name="96" defaultValue={appData.w8ben_additionalConditions} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bg-gray bdr-btm'>
          <Container fluid>
            <h6>Additional information:</h6>
            <Row>
              <Col>
                <div className='label'>Politically exposed</div>
                <div className='value'><input type="text" className='form-data' name="36" defaultValue={appData.pep} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Employment Status</div>
                <div className='value'><input type="text" className='form-data' name="37" defaultValue={appData.employmentStatus} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Occupation</div>
                <div className='value'><input type="text" className='form-data' name="38" defaultValue={appData.occupation} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Company Name</div>
                <div className='value'><input type="text" className='form-data' name="39" defaultValue={appData.companyName} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col xs="auto">
                <div className='label'><b>Company Address</b></div>
                <div className='label'>Address Line 1</div>
                <div className='value'><input type="text" className='form-data' name="40" defaultValue={appData.addressLine1W8ben1} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Address Line 2</div>
                <div className='value'><input type="text" className='form-data' name="41" defaultValue={appData.addressLine2W8ben1} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Country</div>
                <div className='value'><input type="text" className='form-data' name="42" defaultValue={appData.countryW8ben1} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>State / Province / Area</div>
                <div className='value'><input type="text" className='form-data' name="43" defaultValue={appData.stateW8ben1} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>City</div>
                <div className='value'><input type="text" className='form-data' name="44" defaultValue={appData.cityW8ben1} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Zipcode / Postal Code</div>
                <div className='value'><input type="text" className='form-data' name="45" defaultValue={appData.zipcodeW8ben1} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col>
                <div className='label'>Annual Household Income</div>
                <div className='value'>$ <input type="text" className='form-data' name="46" defaultValue={appData.annualIncome} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bg-gray bdr-btm'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs="auto"><img src={require('./../assets/images/secondary-doc-icon.png')} alt="Secondary Document Icon" /></Col>
              <Col xs="auto">
                <div className='label'>Foreign Tax Identification Number</div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='d-flex align-items-center'>
                  <img src={'data:image/jpeg;base64,'+appData.ftinId} height="80" alt="Image" />
                  <span className='ms-3 view' onClick={handleShowFtinId}>Click to view</span>
                  <Modal className='show-picture' show={showFtinId} onHide={handleCloseFtinId} backdrop="static"keyboard={false} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <img src={'data:image/jpeg;base64,'+appData.ftinId} alt="Image" className='img-fluid' />
                    </Modal.Body>
                  </Modal>
                </div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className="position-relative d-inline-block">
                  <label htmlFor="supplementaryFTIN" className='upload upload-default'>
                    <span className="txt-upload">Upload File</span>
                  </label>
                  <input id="supplementaryFTIN" name="117" type="file" accept=".png, .jpg, .pdf" onChange={handleUpload} disabled={applicationReadOnly} />
                </div>
              </Col>
              {appData.supplementaryFTIN && <Col>
                <img src={appData.supplementaryFTIN} height="80" alt="Image" />
                <span className='ms-3 view' onClick={() => handleShowSupplementaryFile(appData.supplementaryFTIN)}>Click to view</span>
              </Col>}
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row>
              <Col xs="auto">
                <div className='label'>Source of Income</div>
                <div className='value'><input type="text" className='form-data' name="47" defaultValue={appData.sourceOfIncome} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='label'>Other Sources</div>
                <div className='value'><input type="text" className='form-data' name="48" defaultValue={appData.otherSources} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row>
              <Col xs="auto">
                <div className='label'>Account Usage</div>
                <div className='value'><input type="text" className='form-data' name="49" defaultValue={appData.useAccountFor} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='label'>Source of initial deposit</div>
                <div className='value'><input type="text" className='form-data' name="50" defaultValue={appData.sourceOfInitialDeposit} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='label'>Other Sources</div>
                <div className='value'><input type="text" className='form-data' name="51" defaultValue={appData.otherSourceOfInitialDeposit} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col className='bdr-right'>
                <div className='label pt-2'>No. of Incoming wires</div>
                <div className='value'><input type="text" className='form-data' name="52" defaultValue={appData.incomingWiresCount} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Expected amount</div>
                <div className='value'><input type="text" className='form-data' name="53" defaultValue={appData.incomingWiresAmt} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col className='bdr-right ps-5'>
                <div className='label pt-2'>No. of outgoing wires</div>
                <div className='value'><input type="text" className='form-data' name="54" defaultValue={appData.outgoingWiresCount} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Expected amount</div>
                <div className='value'><input type="text" className='form-data' name="55" defaultValue={appData.outgoingWiresAmt} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col className='bdr-right ps-5'>
                <div className='label pt-2'>No. of ACH Credits</div>
                <div className='value'><input type="text" className='form-data' name="56" defaultValue={appData.achDepositCount} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Expected amount</div>
                <div className='value'><input type="text" className='form-data' name="57" defaultValue={appData.achDepositAmt} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col className='ps-5'>
                <div className='label pt-2'>No. of ACH Debits</div>
                <div className='value'><input type="text" className='form-data' name="58" defaultValue={appData.achDebitsCount} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Expected amount</div>
                <div className='value'><input type="text" className='form-data' name="59" defaultValue={appData.achDebitsAmt} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col className='bdr-right'>
                <div className='label pt-2'>No. of Check Deposits</div>
                <div className='value'><input type="text" className='form-data' name="60" defaultValue={appData.atmDepositsCount} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Expected amount</div>
                <div className='value'><input type="text" className='form-data' name="61" defaultValue={appData.atmDepositsAmt} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col className='bdr-right ps-5'>
                <div className='label pt-2'>No. of Check Transactions</div>
                <div className='value'><input type="text" className='form-data' name="62" defaultValue={appData.atmWithdrawalsCount} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Expected amount</div>
                <div className='value'><input type="text" className='form-data' name="63" defaultValue={appData.atmWithdrawalsAmt} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col className='bdr-right ps-5'>
                <div className='label pt-2'>No. of Cash Deposits</div>
                <div className='value'><input type="text" className='form-data' name="64" defaultValue={appData.cashDepositsCount} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Expected amount</div>
                <div className='value'><input type="text" className='form-data' name="65" defaultValue={appData.cashDepositsAmt} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
              <Col className='ps-5'>
                <div className='label pt-2'>No. of Cash Withdrawals</div>
                <div className='value'><input type="text" className='form-data' name="66" defaultValue={appData.cashWithdrawalsCount} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
                <div className='label'>Expected amount</div>
                <div className='value'><input type="text" className='form-data' name="67" defaultValue={appData.cashWithdrawalsAmt} onChange={handleDataChange} readOnly={applicationReadOnly} /></div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bg-gray'>
          <Container fluid>
            <h2><img src={require('./../assets/images/agreements-icon.png')} alt="Agreements Icon" className='me-4' />Agreements &amp; Disclosures</h2>
            <Row>
              <Col>
                <ul>
                  <li><img src={require('./../assets/images/check-icon.png')} alt="Check Icon" className='me-2' />Agrees to Electronic Communication disclosure and consent</li>
                </ul>
              </Col>
              <Col>
                <ul>
                  <li><img src={require('./../assets/images/check-icon.png')} alt="Check Icon" className='me-2' />General Account Disclosure (includes Funds Availability Policy and EFT Disclosure)</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='pt-4 pb-3 bdr-btm'>
          <Container fluid>
            <Row>
              <Col xs="auto">
                <h2>User experience rating with us</h2>
              </Col>
              <Col>
                <div id="star-rating" className="star-rating">
                  {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                      <Button variant="star" type="button" key={index} className={index <= appData.rating ? "on" : "off"}></Button>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='pt-4 pb-3 bdr-btm'>
          <Container fluid>
            <Row>
              <Col xs="auto">
                <h2>Time spent in the portal</h2>
              </Col>
              <Col>
                {timeSpentOnPortal}
              </Col>
            </Row>
          </Container>
        </section>
        <section className='pt-5 pb-5'>
          <Container fluid>
            <Button variant="transparent" onClick={() => navigate('/application-details?'+applicationID)}>Cancel</Button>
            <Button variant="primary" className='ms-3' onClick={handleSaveData} disabled={applicationReadOnly}>Save</Button>
          </Container>
        </section>

        <Modal show={showErrorPopup} onHide={handleCloseErrorPopup} centered backdrop="static" keyboard={false} className="modal-small">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p className="text-center"><i className="fas fa-exclamation-triangle mr-2"></i> {errorPopupText}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={handleCloseErrorPopup}>Close</Button>
          </Modal.Footer>
        </Modal>
      </main>
    </>
  );
}

export default ApplicationDetailsEdit;
