import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Button, Modal, Form} from 'react-bootstrap';
import axios from "axios";
import Header from '../components/Header';
import AppDataGrid from '../components/AppDataGrid';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");

const ApplicationList = ({handleLogout}) => {

  const mToken = sessionStorage.getItem('mToken');

  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const handleCloseInvitePopup = () => {
    setInputs({});
    setShowInvitePopup(false);
  }
  const handleShowInvitePopup = () => setShowInvitePopup(true);
  
  const [inviteSentMessage, setInviteSentMessage] = useState('');
  const [showAlreadySentPopup, setShowAlreadySentPopup] = useState(false);
  const handleCloseAlreadySentPopup = () => setShowAlreadySentPopup(false);
  const handleShowAlreadySentPopup = () => setShowAlreadySentPopup(true);

  const [applicationCount, setApplicationCount] = useState({});
  
  const [showApplications, setShowApplications] = useState('all');

  const [sendInviteDisabled, setSendInviteDisabled] = useState(true);
  
  const [inputs, setInputs] = useState({});
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  useEffect(() => {
    //Refresh app data grid on load of the page - sometimes app data grid doesn't refresh
    setShowApplications('');
    setTimeout(() => {
      setShowApplications('all');
    }, 100);
  }, []);

  useEffect(() => {
    console.log('mToken', mToken);
    console.log(sessionStorage.getItem('mToken'));
    
    let setAppCount = (filter, setterCallback) => {
      axios.post(serverConfig.api_base_url + "application_list_get", {filter_check: filter, token:mToken})
      .then(response=>{
        console.log("application_list_get " + filter + " response:", response);
        if (response.data.status === 200) {
          setterCallback(JSON.parse(response.data.items).length);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    };

    if(mToken !== null && mToken !== undefined){
      console.log('mToken', mToken);
      /* user_check_privilege - create_invite */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:mToken, privilege:'create_invite'})
      .then(response=>{
        console.log("user_check_privilege response:", response);
        if(response.data.is_privileged === 0){
          setSendInviteDisabled(true);
        } else if(response.data.is_privileged === 1){
          setSendInviteDisabled(false);
        }
      })
      .catch(err=>console.log("error is",err));
      /* Application Counts */
      axios.post(serverConfig.api_base_url + "application_count_get",{token:mToken, privilege:'create_invite'})
      .then(response=>{
        console.log("application_count_get response: ", response);
        if (response.data.status === 200) {
          setApplicationCount(response.data.output);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      } )
      .catch(err=>console.log("error is",err));
    }
  }, [mToken]);

  const handleSendInvite = (e) => {
    e.preventDefault();
    var formdata = {
      token:mToken, ...inputs, force_flag:0
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "create_invite",formdata)
    .then(response=>{
      console.log("Send Invite response:", response);
      console.log(response.data.ref_id);
      if(response.data.status === 200){
        handleCloseInvitePopup();
      } else if(response.data.status === 210){
        setInviteSentMessage(response.data.message);
        handleShowAlreadySentPopup();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const handleProceedSendInvite = (e) => {
    e.preventDefault();
    var formdata = {
      token:mToken, ...inputs, force_flag:1
    }
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "create_invite",formdata)
    .then(response=>{
      console.log("Proceed with Send Invite response:", response);
      console.log(response.data.ref_id);
      if(response.data.status === 200){
        handleCloseAlreadySentPopup();
        handleCloseInvitePopup();
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const refreshAppList =(e) => {
    e.preventDefault();
    axios.post(serverConfig.api_base_url + "application_list_get",{filter_check:'all', token:mToken})
    .then(response=>{
      console.log("application_list_get response:", response);
      console.log("application_list_get response:", response.data.status);
      if(response.data.status === 200){
        setShowApplications('');
        setTimeout(() => {
          setShowApplications('all');
        }, 100);
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  return (
    <>
      <h1>New Account Onboarding Dashboard</h1>
      <section>
        <Row className='application-stats'>
          <Col>
            <div className='inner' onClick={() => setShowApplications('all')}>
              <h4>Total Applications</h4>
              <div className='count-wrap'>
                <span className='count'><span>{'total' in applicationCount ? applicationCount.total : '0'}</span>Nos.</span>
                <span><img src={require('./../assets/images/application-icon.png')} alt="Application icon" /></span>
              </div>
            </div>
          </Col>
          <Col>
            <div className='inner' onClick={() => setShowApplications('under review')}>
              <h4>Applications Under Review</h4>
              <div className='count-wrap'>
                <span className='count'><span>{'submitted' in applicationCount ? applicationCount.submitted : '0'}</span>Nos.</span>
                <span><img src={require('./../assets/images/new-applications-icon.png')} alt="New Applications icon" /></span>
              </div>
            </div>
          </Col>
          <Col>
            <div className='inner' onClick={() => setShowApplications('approved')}>
              <h4>Accepted Applications</h4>
              <div className='count-wrap'>
                <span className='count'><span>{'approved' in applicationCount ? applicationCount.approved : '0'}</span>Nos.</span>
                <span><img src={require('./../assets/images/approved-application-icon.png')} alt="Approved Application icon" /></span>
              </div>
            </div>
          </Col>
          <Col>
            <div className='inner' onClick={() => setShowApplications('denied')}>
              <h4>Not Accepted Applications</h4>
              <div className='count-wrap'>
                <span className='count'><span>{'denied' in applicationCount ? applicationCount.denied : '0'}</span>Nos.</span>
                <span><img src={require('./../assets/images/denied-application-icon.png')} alt="Denied Application icon" /></span>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section>
        <Button variant="transparent" className='btn-send-invite' onClick={handleShowInvitePopup} disabled={sendInviteDisabled}>Send invite to new customer</Button>
        <Modal show={showInvitePopup} onHide={handleCloseInvitePopup} backdrop="static"keyboard={false} centered>
          <Form onSubmit={handleSendInvite}>
            <Modal.Header closeButton>
              <Modal.Title>New Customer Invite</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Name" name="name" value={inputs.name || ""} onChange={handleChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="email" placeholder="Email" name="app_email_id" value={inputs.app_email_id || ""} onChange={handleChange} required />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control type="text" placeholder="Mobile No." name="phone_number" value={inputs.phone_number || ""} onChange={handleChange} required />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="transparent" onClick={handleCloseInvitePopup}>Cancel</Button>
              <Button variant="primary" type="submit" className='ms-3'><img src={require('./../assets/images/sent-invite-icon-white.png')} alt="Sent Invite Icon" className='me-2' />Send Invite</Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal show={showAlreadySentPopup} onHide={handleCloseAlreadySentPopup} backdrop="static"keyboard={false} centered className='content-text'>
          <Modal.Body>
            <p>{inviteSentMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={handleCloseAlreadySentPopup}>No</Button>
            <Button variant="primary" className='ms-3' onClick={handleProceedSendInvite}>Yes</Button>
          </Modal.Footer>
        </Modal>
      </section>
      <section>
        <div className='datatable datatable-app-list'>
          <h2>Applications <button className='ms-3' onClick={refreshAppList}><i className="fas fa-sync"></i></button></h2>
          {/* onClick={() => setShowApplications('all')} */}
          <AppDataGrid show={showApplications} />
        </div>
      </section>
    </>
  );
}

export default ApplicationList;
