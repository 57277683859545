import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/styles.css';
import RenderOnAnonymous from "./RenderOnAnonymous";
import RenderOnAuthenticated from "./RenderOnAuthenticated";

import ApplicationDetails from '../pages/ApplicationDetails';
import ApplicationDetailsEdit from '../pages/ApplicationDetailsEdit';
import Home from '../pages/Home';

function App() {
  return (
    <BrowserRouter>
      <RenderOnAnonymous />
      <RenderOnAuthenticated>
        <Routes>
          <Route path="/application-details" element={<ApplicationDetails />} />
          <Route path="/application-details-edit" element={<ApplicationDetailsEdit />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </RenderOnAuthenticated>
    </BrowserRouter>
  );
}

export default App;