import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { MDBDataTable } from "mdbreact";
import axios from "axios";

const serverConfig = require("../config/server.js");

function AppDataGrid(props) {
  
  const mToken = sessionStorage.getItem('mToken');

  const [appDataGrid, setAppDataGrid] = useState([]);
  const [appDataGridRender, setAppDataGridRender] = useState([]);

  const handleClick = (link) => {
    console.log('clicked', link);
    window.location = link;
  }

  useEffect(() => {
    /* application_list_get */
    console.log('props', props.show);
    console.log(sessionStorage.getItem('mToken'));
    
    if(mToken !== null && mToken !== undefined){
      console.log('mToken', mToken);
      axios.post(serverConfig.api_base_url + "application_list_get",{filter_check:props.show, token:mToken})
      .then(response=>{
        let items = response.data.items;
        if (items !== null || items !== undefined){
          console.log("application_list_get response items:", items);
          if(items){
            setAppDataGrid(items);
            setAppDataGrid((stateAppDataGrid) => {
              let appDataArray = JSON.parse(JSON.stringify(stateAppDataGrid)); // deep copy?
              let appData = [];
              console.log(appDataArray);
              appDataArray.sort((a, b) => b.last_updated.localeCompare(a.last_updated)).map((item, index) => {
                let id_no = item.application_id;
                if(item.current_status.match(/^invite /)) {
                  if(item.current_status === 'invite sent'){
                    item.current_status = [<img src={require('./../assets/images/sent-invite-icon.png')} alt="Sent Invite Icon" height="15" className='me-2' />, 'Invite Sent']
                  } else if(item.current_status === 'invite expired'){
                    item.current_status = [<i key="Invite Expired" className="fas fa-exclamation-circle me-2 txt-red" aria-hidden="true"></i>, 'Invite Expired']
                  }
                  item.actions = (<>
                    {/* <button className='btn-icon me-5' disabled><i className="far fa-eye txt-gray"></i></button>
                    <button className='btn-icon me-5' disabled><i className="far fa-check-square txt-green"></i></button>
                    <button className='btn-icon' disabled><i className="far fa-times-circle txt-red"></i></button> */}
                  </>)
                } else {
                  if(item.current_status === 're-invite sent'){
                    item.current_status = [<img src={require('./../assets/images/sent-invite-icon.png')} alt="Sent Invite Icon" height="15" className='me-2' />, 'Re-invite Sent'];
                    item.actions = (<>
                      <Link to={"application-details?" + id_no}><i className="far fa-eye txt-gray"></i></Link>
                    </>);
                    item.clickEvent= () => handleClick("application-details?" + id_no);
                    item.rowClassNames= 'clickable';
                  } else if(item.current_status === 'invalidated and re-invited'){
                    item.current_status = [<img src={require('./../assets/images/sent-invite-icon.png')} alt="Sent Invite Icon" height="15" className='me-2' />, 'Re-invite Sent']
                  } else if (item.current_status === 'under review') {
                    item.current_status = [<img src={require('./../assets/images/pending-icon.png')} alt="Pending Icon" className='me-2' />, 'Under Review']
                    item.application_id = [id_no, <span className='new'>NEW</span>];
                    item.actions = (<>
                      <Link to={"application-details?" + id_no}><i className="far fa-eye txt-gray"></i></Link>
                    </>);
                    item.clickEvent= () => handleClick("application-details?" + id_no);
                    item.rowClassNames= 'clickable';
                  } else {
                    if(item.current_status === 'approved'){
                      item.current_status = [<i key="Accepted" className="far fa-check-square me-2 txt-green" aria-hidden="true"></i>, 'Accepted']
                    } else if(item.current_status === 'denied'){
                      item.current_status = [<i key="Not Accepted" className="far fa-times-circle me-2 txt-red" aria-hidden="true"></i>, 'Not Accepted']
                    } else {
                      item.current_status = item.current_status
                    }
                    item.actions = (<>
                      <Link to={"application-details?" + id_no}><i className="far fa-eye txt-gray"></i></Link>
                    </>);
                    item.clickEvent= () => handleClick("application-details?" + id_no);
                    item.rowClassNames= 'clickable';
                  }
                }
                item.last_updated = (item.last_updated).substr(0, (item.last_updated).length-7);
                appData.push(item);
              });
              console.log(appData);
              setAppDataGridRender(appData);
              return stateAppDataGrid;
            });
          }
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [props]);

  const data = {
    columns: [
      {
        label: 'ID No.',
        field: 'application_id',
        sort: 'disabled',
        width: 250
      },
      {
        label: 'Name of Applicant',
        field: 'name',
        sort: 'disabled',
        width: 270
      },
      {
        label: 'Contact No.',
        field: 'phone_number',
        sort: 'disabled',
        width: 200
      },
      {
        label: 'Email Id',
        field: 'email_id',
        sort: 'disabled',
        width: 200
      },
      {
        label: 'Status',
        field: 'current_status',
        sort: 'disabled',
        width: 500
      },
      {
        label: 'Last Updated',
        field: 'last_updated',
        sort: 'disabled',
        width: 250
      },
      {
        label: 'Actions',
        field: 'actions',
        sort: 'disabled',
        width: 250
      }
    ],

    rows: appDataGridRender,
  };

  return (
    <MDBDataTable striped borderless data={data} entries={10} entriesOptions={[ 10, 20, 30 ]} searchLabel="Type here"/>
  );
}

export default AppDataGrid;
