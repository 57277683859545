import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Button, Form} from 'react-bootstrap';
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { CSVLink } from "react-csv";
import Header from '../components/Header';
import UserService from '../services/UserService';

const serverConfig = require("../config/server.js");

const ApplicationLog = ({handleLogout}) => {

  const mToken = sessionStorage.getItem('mToken');

  const [appDataGridRender, setAppDataGridRender] = useState();
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }
  
  const handleShowLog = (e) => {
    e.preventDefault();
    console.log(mToken);
    console.log(sessionStorage.getItem('mToken'));
    var formdata = {
      token:mToken, application_id: '', ...inputs
    }
    console.log('parameters passed',formdata);
    axios.post(serverConfig.api_base_url + "application_log_get",formdata)
    .then(response=>{
      console.log("application_log_get response:", response);
      console.log("application_log_get response items:", response.data.items);
      if(response.data.status === 200){
        setAppDataGridRender(response.data.items.sort((a, b) => b.stamp.localeCompare(a.stamp)));
        //setInputs({});
      } else if(response.data.status === 301){
        handleLogout();
      } else {
        alert(response.data.message);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const data = {
    columns: [
      { label: 'Application ID', field: 'application_id', sort: true, key: 'application_id' },
      { label: 'Timestamp', field: 'stamp', sort: false, key: 'stamp' },
      { label: 'Name', field: 'name', sort: true, key: 'name' },
      { label: 'Application Status', field: 'application_status', sort: false, key: 'application_status' },
      { label: 'Exception', field: 'exception', sort: false, key: 'exception' },
      { label: 'Exception Status', field: 'exception_status', sort: false, key: 'exception_status' },
      { label: 'Updated By', field: 'updated_by', sort: false, key: 'updated_by' },
    ],
    rows: appDataGridRender,
  };

  return (
    <>
      <section>
        <Form onSubmit={handleShowLog}>
          <Row className='align-items-center'>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Start Date<span className="txt-red">*</span></Form.Label>
                <Form.Control type="date" name="start_date" value={inputs.start_date || ""} max={new Date().toISOString().split("T")[0]} onChange={handleChange} required />
                </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>End Date<span className="txt-red">*</span></Form.Label>
                <Form.Control type="date" name="end_date" value={inputs.end_date || ""} max={new Date().toISOString().split("T")[0]} onChange={handleChange} required />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Application ID</Form.Label>
                <Form.Control type="text" name="application_id" value={inputs.application_id || ""} onChange={handleChange} />
              </Form.Group>
            </Col>
            <Col>
              <Button variant="primary" type="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      </section>
      <section>
        {appDataGridRender &&
          <div className='datatable datatable-app-log'>
            <h2>Application Log
              <CSVLink className='ms-3' data={data.rows} headers={data.columns} filename={"application-log.csv"}><button><i className="fas fa-download"></i></button></CSVLink>
            </h2>
            <MDBDataTable data={data} />
          </div>
        }
      </section>
    </>
  );
}

export default ApplicationLog;
