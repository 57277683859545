import React, {useState, useEffect} from 'react';
import { Container, Tab, Tabs} from 'react-bootstrap';
import Header from '../components/Header';
import ApplicationList from './ApplicationList';
import ApplicationLog from './ApplicationLog';
import Users from './Users';
import UserService from '../services/UserService';
import axios from 'axios';

const serverConfig = require("../config/server.js");

const Home = () => {

  const [mToken, setMToken] = useState(null);
  const [privilegeReadAppList, setPrivilegeReadAppList] = useState(false);
  const [privilegeReadUserList, setPrivilegeReadUserList] = useState(false);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('mToken'));
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('mToken', response.data.token);
        setMToken(response.data.token);
      } else if(response.data.jwt_validation === 'fail') {
        handleLogout();
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(sessionStorage.getItem('mToken'));
    if(sessionStorage.getItem('mToken') === null){
      getToken();
    }
  }, []);

  useEffect(() => {
    console.log('mToken', mToken);
    console.log(sessionStorage.getItem('mToken'));
    console.log(privilegeReadAppList);
    setPrivilegeReadAppList(false);
    setPrivilegeReadUserList(false);
    
    if(sessionStorage.getItem('mToken') !== null && sessionStorage.getItem('mToken') !== undefined){
      /* user_check_privilege - read_user_list */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:sessionStorage.getItem('mToken'), privilege:'read_user_list'})
      .then(response=>{
        console.log("read_user_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setPrivilegeReadUserList(false);
          } else if(response.data.is_privileged === 1){
            setPrivilegeReadUserList(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));

      /* user_check_privilege - read_application_list */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:sessionStorage.getItem('mToken'), privilege:'read_application_list'})
      .then(response=>{
        console.log("read_application_list privilege response:", response);
        if(response.data.status === 200){
          if(response.data.is_privileged === 0){
            setPrivilegeReadAppList(false);
          } else if(response.data.is_privileged === 1){
            setPrivilegeReadAppList(true);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [mToken, sessionStorage.getItem('mToken')]);

  return (
    <>
      <Header />
      <main>
        <Container fluid>
          <Tabs defaultActiveKey="applications" transition={false} className="mb-5">
            <Tab eventKey="applications" title="Applications">
              <ApplicationList handleLogout={handleLogout} isUserPrivileged={privilegeReadUserList} />
            </Tab>
            <Tab eventKey="audit" title="Audit Log">
              <ApplicationLog handleLogout={handleLogout} />
            </Tab>
            {privilegeReadUserList &&
              <Tab eventKey="users" title="Users">
                <Users handleLogout={handleLogout} />
              </Tab>
            }
          </Tabs>
        </Container>
      </main>
    </>
  );
}

export default Home;