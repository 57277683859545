import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Form, Button, Modal, Accordion} from 'react-bootstrap';
import { useNavigate  } from "react-router-dom";
import axios from "axios";
import Header from '../components/Header';
import Back from '../components/back';
import UserService from '../services/UserService';
import HashUtil from '../services/HashUtil';
import FileSaver from 'file-saver';

const serverConfig = require("../config/server.js");

const ApplicationDetails = () => {

  const navigate = useNavigate();

  const [mToken, setMToken] = useState(sessionStorage.getItem('mToken'));

  var url = window.location.href;
  var applicationID = url.substring(url.indexOf('?')+1);
  console.log('applicationID', applicationID);

  const [appData, setAppData] = useState('');
  const [appDataEmailid, setAppDataEmailid] = useState('');
  const [timeSpentOnPortal, setTimeSpentOnPortal] = useState('');
  const [docValData, setDocValData] = useState([]);
  const [exceptionList, setExceptionList] = useState([]);

  const [acceptDisabled, setAcceptDisabled] = useState(true);
  const [sendInviteDisabled, setSendInviteDisabled] = useState(true);
  const [approveExceptionDisabled, setApproveExceptionDisabled] = useState(true);
  const [denyApplicationEnabled, setDenyApplicationEnabled] = useState(false);
  const [applicationReadOnly, setApplicationReadOnly] = useState(true);

  const [isLoading, setIsLoading] = useState(true);

  const [lastUpdated, setLastUpdated] = useState('');

  const[file, setFile] = useState('');
  const [showFileModal, setShowFileModal] = useState(false);

  /* Passport */
  const [showPassport, setShowPassport] = useState(false);
  const handleClosePassport = () => setShowPassport(false);
  const handleShowPassport = () => setShowPassport(true);
  /* Supplementary Files */
  const [supplementaryFile, setSupplementaryFile] = useState("");
  const [showSupplementaryFile, setShowSupplementaryFile] = useState(false);
  const handleCloseSupplementaryFile = () => setShowSupplementaryFile(false);
  const handleShowSupplementaryFile = (filedata) => {
    setSupplementaryFile(filedata);
    setShowSupplementaryFile(true);
  }
  /* Secondary Doc */
  const [showSecondaryDoc, setShowSecondaryDoc] = useState(false);
  const handleCloseSecondaryDoc = () => setShowSecondaryDoc(false);
  const handleShowSecondaryDoc = () => setShowSecondaryDoc(true);
  /* Selfie Picture */
  const [showSelfiePicture, setShowSelfiePicture] = useState(false);
  const handleCloseSelfiePicture = () => setShowSelfiePicture(false);
  const handleShowSelfiePicture = () => setShowSelfiePicture(true);
  /* FTIN ID */
  const [showFtinId, setShowFtinId] = useState(false);
  const handleCloseFtinId = () => setShowFtinId(false);
  const handleShowFtinId = () => setShowFtinId(true);
  
  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleCloseConfirmation = () => setShowConfirmation(false);
  const handleShowConfirmation = () => setShowConfirmation(true);

  const [showInvite, setShowInvite] = useState(false);
  const handleCloseInvite = () => setShowInvite(false);
  const handleShowInvite = () => setShowInvite(true);

  const [errorMsg, setErrorMsg] = useState('');
  const [showError, setShowError] = useState(false);
  const handleCloseError = () => setShowError(false);
  const handleShowError = () => setShowError(true);

  const handleLogout = () => {
    UserService.doLogout();
    sessionStorage.clear();
  }

  const handleApproveYes = () => {
    axios.post(serverConfig.api_base_url + "approval_set",{token:mToken, app_id:applicationID, approval:'approve'})
    .then(response=>{
      console.log("approval_set approve response:", response);
      if(response.data.status === 200){
        handleCloseConfirmation();
        navigate('/');
      } else if(response.data.status === 301){
        handleLogout();
      } else if(response.data.status === 404){
        setErrorMsg(response.data.message);
        handleShowError();
        setTimeout(() => {
          handleCloseError();
        }, 2000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [showConfirmationDenial, setShowConfirmationDenial] = useState(false);
  const handleCloseConfirmationDenial = () => setShowConfirmationDenial(false);
  const handleShowConfirmationDenial = () => setShowConfirmationDenial(true);
  const handleDenyYes = () => {
    axios.post(serverConfig.api_base_url + "approval_set",{token:mToken, app_id:applicationID, approval:'deny'})
    .then(response=>{
      console.log("approval_set deny response:", response);
      if(response.data.status === 200){
        handleCloseConfirmationDenial();
        navigate('/');
      } else if(response.data.status === 301){
        handleLogout();
      } else if(response.data.status === 404){
        setErrorMsg(response.data.message);
        handleShowError();
        setTimeout(() => {
          handleCloseError();
        }, 2000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const formatDateTime = (datestr) => {
    var d = new Date(datestr);
    var dd = String(d.getDate()).padStart(2, '0');
    var mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = d.getFullYear();
    var hh = String(d.getHours()).padStart(2, '0');
    var min = String(d.getMinutes()).padStart(2, '0');
    var ss = String(d.getSeconds()).padStart(2, '0');
    return mm + '/' + dd + '/' + yyyy + ', ' + hh + ':' + min + ':' + ss;
  }

  const handleReInvite = (e) => {
    e.preventDefault();
    console.log('reinvite');
    console.log(applicationID);
    var formdata = {token:mToken, app_id:applicationID}
    console.log(formdata);
    axios.post(serverConfig.api_base_url + "user_reinvite",formdata)
    .then(response=>{
      console.log("Re-Invite response:", response);
      if(response.data.status === 200){
        console.log('re-invite sent');
        handleCloseInvite();
        navigate('/');
      } else if(response.data.status === 301){
        handleLogout();
      } else if(response.data.status === 404){
        setErrorMsg(response.data.message);
        handleShowError();
        setTimeout(() => {
          handleCloseError();
        }, 2000);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  const [exceptionInputs, setExceptionInputs] = useState([]);
  const handleChange = (event) => {
    //console.log('handle change');
    const exceptionId = event.target.id;
    //console.log(exceptionId);
    if(event.target.type === 'checkbox'){
      if (event.target.checked) {
        if(event.target.parentElement.classList.contains('N')){
          event.target.parentElement.querySelector('.reasons').classList.add('d-block');
          event.target.parentElement.querySelector('.reasons').classList.remove('d-none');
          event.target.parentElement.querySelector('.reasons textarea').required = true;
        }
        setExceptionInputs(values => ([...values, {exception_id:exceptionId}]));
        //setExceptionInputs(values => ([...values, exceptionId]));
        
      } else {
        if(event.target.parentElement.classList.contains('N')){
          event.target.parentElement.querySelector('.reasons').classList.add('d-none');
          event.target.parentElement.querySelector('.reasons').classList.remove('d-block');
          event.target.parentElement.querySelector('.reasons textarea').required = false;
          event.target.parentElement.querySelector('.reasons textarea').value = '';
        }
        setExceptionInputs(exceptionInputs.filter(e => e.exception_id !== exceptionId));
        //setExceptionInputs(exceptionInputs.filter(e => e !== exceptionId));
        
      }
      console.log(exceptionInputs);
    } else if(event.target.type === 'textarea'){
      console.log(exceptionInputs);
      console.log(event.target.name, event.target.value);
      const newArr = exceptionInputs.map(ei => {
        return ei.exception_id === event.target.name ? {...ei, exception_reason: event.target.value} : ei
      });
      console.log(newArr);
      setExceptionInputs(newArr);
      console.log(exceptionInputs);
    }
  }
  const handleApproveExceptions = (e) => {
    e.preventDefault();
    console.log('handle approve exceptions', exceptionInputs);
    //console.log(Object.keys(exceptionInputs).length);
    if(Object.keys(exceptionInputs).length > 0) {
      axios.post(serverConfig.api_base_url + "application_exception_approve",{token:mToken, exception_list:exceptionInputs, approve:1})
      .then(response=>{
        console.log("application_exception_approve response:", response);
        if(response.data.status === 200){
          //window.location.reload();
          setExceptionInputs([]);
          var x = document.getElementsByClassName("checkbox");
          let i;
          for(i=0; i<=x.length; i++) {
            x[i].checked = false;
          }
        } else if(response.data.status === 301){
          handleLogout();
        }
        //window.location.reload();
        /* var x = document.getElementsByClassName("checkbox");
        let i;
        for(i=0; i<=x.length; i++) {
          x[i].checked = false;
        }
        setExceptionInputs([]); */
      })
      .catch(err=>console.log("error is",err));
    }
  }
  const handleDisapproveExceptions = (e) => {
    e.preventDefault();
    console.log('handle disapprove exceptions', exceptionInputs);
    //console.log(Object.keys(exceptionInputs).length);
    if(Object.keys(exceptionInputs).length > 0) {
      axios.post(serverConfig.api_base_url + "application_exception_approve",{token:mToken, exception_list:exceptionInputs, approve:0})
      .then(response=>{
        console.log("Disapprove application_exception_approve response:", response);
        if(response.data.status === 200){
          //window.location.reload();
          setExceptionInputs([]);
          var x = document.getElementsByClassName("checkbox");
          let i;
          for(i=0; i<=x.length; i++) {
            x[i].checked = false;
          }
        } else if(response.data.status === 301){
          handleLogout();
        }
        //window.location.reload();
        /* var x = document.getElementsByClassName("checkbox");
        let i;
        for(i=0; i<=x.length; i++) {
          x[i].checked = false;
        }
        setExceptionInputs([]); */
      })
      .catch(err=>console.log("error is",err));
    }
  }

  const rotateClockwise = (event) => {
    var img = event.target.closest(".show-picture").querySelector(".modal-body img");
    if(img.classList.contains('deg-0')){
      img.setAttribute('class','deg-90');
    } else if(img.classList.contains('deg-90')){
      img.setAttribute('class','deg-180');
    } else if(img.classList.contains('deg-180')){
      img.setAttribute('class','deg-270');
    } else if(img.classList.contains('deg-270')){
      img.setAttribute('class','deg-0');
    }
  }

  const rotateAnticlockwise = (event) => {
    var img = event.target.closest(".show-picture").querySelector(".modal-body img");
    if(img.classList.contains('deg-0')){
      img.setAttribute('class','deg-270');
    } else if(img.classList.contains('deg-90')){
      img.setAttribute('class','deg-0');
    } else if(img.classList.contains('deg-180')){
      img.setAttribute('class','deg-90');
    } else if(img.classList.contains('deg-270')){
      img.setAttribute('class','deg-180');
    }
  }

  const showFile = (entityname) => {
    setIsLoading(true);
    document.body.classList.add('loader-show');
    console.log('get file');
    setIsLoading(false);
    document.body.classList.remove('loader-show');
    console.log('loaded');

    setFile(entityname);
    setShowFileModal(true);
  }

  const downloadFile = (entityname, filename) => {
    setIsLoading(true);
    document.body.classList.add('loader-show');
    setIsLoading(false);
    document.body.classList.remove('loader-show');
    console.log('loaded');

    alert('The File will now be downloaded into the folder that saves all your "Downloads".');
    FileSaver.saveAs(entityname, filename);
  }

  const getToken = () => {
    console.log(sessionStorage.getItem('mToken'));
    //let emailid = UserService.getUsername();
    //console.log('email', emailid);
    let jwt = UserService.getToken();
    axios.post(serverConfig.api_base_url + "user_get_token",{jwt:jwt})
    .then(response=>{
      console.log('user_get_token response', response);
      if(response.data.status === 200){
        sessionStorage.setItem('mToken', response.data.token);
        setMToken(response.data.token);
      }
    })
    .catch(err=>console.log("error is",err));
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(sessionStorage.getItem('mToken'));
    if(sessionStorage.getItem('mToken') === null){
      getToken();
    }
  }, []);

  useEffect(() => {
    if(mToken !== null && mToken !== undefined){
      /* Application Counts */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:mToken, privilege:'create_invite'})
      .then(response=>{
        console.log("user_check_privilege response:", response.data.is_privileged);
        if(response.data.is_privileged === 1){
          setSendInviteDisabled(false);
        }
      } )
      .catch(err=>console.log("error is",err));
      /* user_check_privilege - approve_exception */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:mToken, privilege:'approve_exception'})
      .then(response=>{
        console.log("user_check_privilege response:", response.data.is_privileged);
        if(response.data.is_privileged === 0){
          setApproveExceptionDisabled(true);
        } else if(response.data.is_privileged === 1){
          setApproveExceptionDisabled(false);
        }
      })
      .catch(err=>console.log("error is",err));

      /* user_check_privilege - approve_application */
      axios.post(serverConfig.api_base_url + "user_check_privilege",{token:mToken, privilege:'approve_application'})
      .then(response=>{
        console.log("user_check_privilege response:", response);
        console.log("user_check_privilege response:", response.data.is_privileged);
        let denyAppEn = response.data.is_privileged !== 0;
        setDenyApplicationEnabled(denyAppEn);
        console.log("denyAppEnabled=" + denyAppEn);
      })
      .catch(err=>console.log("error is",err));

      /* application_data_get */
      console.log('mToken', mToken);
      axios.post(serverConfig.api_base_url + "application_data_get",{token:mToken, app_id:applicationID})
      .then(response=>{
        setIsLoading(false);
        console.log(mToken);
        console.log("application_data_get response:", response);
        console.log("application_data_get response:", response.data.output_data);
        console.log(response.data.status);
        if(response.data.status === 200){
          console.log('status 200');
          setAppDataEmailid(response.data.output_data.app_email_id);
          //console.log("application_data_get response:", JSON.parse(response.data.output_data).app_data);
          setAppData(response.data.output_data.app_data);
          setAppData((stateAppData) => {
            if(stateAppData !== null) {
              /* captureSelfiePassport */
              if(stateAppData.captureSelfiePassport){
                if(stateAppData.captureSelfiePassport !== null &&
        'FAIL' !== stateAppData.captureSelfiePassport.Status) {
                  console.log(JSON.parse(stateAppData.captureSelfiePassport).FormDetails);
                  setDocValData(HashUtil.copyObjectSubKeyVals(
                    JSON.parse(stateAppData.captureSelfiePassport).FormDetails.Identity_Validation_and_Face_Matching.IDmission_Image_Processing.ID_Image_Processing, '#text', {
                    name: 'Name_Doc',
                    gender: 'Gender',
                    address: 'Address',
                    country: 'Country',
                    MRZValid: 'MRZValid',
                    MRZ_Data: 'MRZ_Data',
                    MRZ_Name: 'MRZ_Name',
                    Issue_Date: 'Issue_Date',
                    MRZ_Gender: 'MRZ_Gender',
                    Nationality: 'Nationality',
                    AddressLine1: 'AddressLine1',
                    AddressLine2: 'AddressLine2',
                    MRZIDExpired: 'MRZIDExpired',
                    MRZ_Detected: 'MRZ_Detected',
                    MRZ_IDNumber: 'MRZ_IDNumber',
                    PlaceofIssue: 'PlaceofIssue',
                    Date_of_Birth: 'Date_of_Birth',
                    IssuingCountry: 'IssuingCountry',
                    MRZ_ExpiryDate: 'MRZ_ExpiryDate',
                    Place_of_Birth: 'Place_of_Birth',
                    Expiration_Date: 'Expiration_Date',
                    MRZDataExpected: 'MRZDataExpected',
                    MRZ_DateofBirth: 'MRZ_DateofBirth',
                    MRZ_Nationality: 'MRZ_Nationality',
                    MRZErrorMessages: 'MRZErrorMessages',
                    Issuing_Authority: 'Issuing_Authority',
                    MRZ_IssuingCountry: 'MRZ_IssuingCountry',
                    MRZDataExpectedOnBack: 'MRZDataExpectedOnBack',
                    MRZDataExpectedOnFront: 'MRZDataExpectedOnFront'
                  } ));
                }
              }
              if(stateAppData.controlProcessStartTime && stateAppData.controlProcessFinishTime){
                let finish_date = new Date(stateAppData.controlProcessFinishTime);
                let start_date = new Date(stateAppData.controlProcessStartTime);
                // finding the difference in total seconds between two dates
                let second_diff = (finish_date.getTime() - start_date.getTime()) / 1000;
                // showing the relative timestamp.
                if (second_diff < 60) {
                  console.log("difference is of " + second_diff + " seconds.");
                  setTimeSpentOnPortal(second_diff.toFixed(2) + " seconds.");
                } else if (second_diff < 3600) {
                  console.log("difference is of " + second_diff / 60 + " minutes.");
                  setTimeSpentOnPortal((second_diff / 60).toFixed(2) + " minutes.");
                } else if (second_diff < 86400) {
                  console.log("difference is of " + second_diff / 3600 + " hours.");
                  setTimeSpentOnPortal((second_diff / 3600).toFixed(2) + " hours.");
                } else if (second_diff < 2620800) {
                  console.log("difference is of " + second_diff / 86400 + " days.");
                  setTimeSpentOnPortal((second_diff / 86400).toFixed(2) + " days.");
                } else if (second_diff < 31449600) {
                  console.log("difference is of " + second_diff / 2620800 + " months.");
                  setTimeSpentOnPortal((second_diff / 2620800).toFixed(2) + " months.");
                } else {
                  console.log("difference is of " + second_diff / 31449600 + " years.");
                  setTimeSpentOnPortal((second_diff / 31449600).toFixed(2) + " years.");
                }
              }
            }
            return stateAppData;
          });
          if(response.data.output_data.current_status === "re-invite sent"){
            console.log();
            setApplicationReadOnly(true);
          } else {
            setApplicationReadOnly(false);
          }
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
      console.log(appData);

      /* get last updated timestamp from application log API */
      axios.post(serverConfig.api_base_url + "application_log_get",{token:mToken, application_id: applicationID, start_date: '2022-06-28', end_date: new Date().toISOString().slice(0, 10)})
      .then(response=>{
        console.log("application_log_get response:", response);
        let arr = response.data.items;
        console.log("application_log_get response items:", arr);
        if(response.data.status === 200){
          setLastUpdated(arr.reverse()[0].stamp.split('.')[0]);
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [mToken]);

  useEffect(() => {
    console.log(exceptionInputs);
    //disable Accept button if any of the validations is not passed and user does not have approve_application rights
    if(mToken !== null && mToken !== undefined){
      /* application_exception_get API */
      axios.post(serverConfig.api_base_url + "application_exception_get",{token:mToken, application_id:applicationID})
      .then(response=>{
        console.log("application_exception_get response:", response);
        console.log("application_exception_get response:", response.data.output_data);
        console.log("application_exception_get response:", response.data.status);
        if(response.data.status === 200){
          console.log('status 200');
          if (response.data.output_data !== null && response.data.output_data !== undefined)
          setExceptionList(response.data.output_data);
          setExceptionList((stateExceptionList) => {
            //disable Accept button if any of the validations is not passed and user does not have approve_application rights
            if (stateExceptionList.filter(e => e.passed === 'N').length > 0) {
              setAcceptDisabled(true);
            } else {
                /* user_check_privilege - approve_application */
                axios.post(serverConfig.api_base_url + "user_check_privilege",{
                        token:mToken,
                        privilege:'approve_application'
                }).then(response=>{
                        console.log("user_check_privilege response:", response.data.is_privileged);
                        if(response.data.is_privileged === 0){
                                setAcceptDisabled(true);
                        } else if(response.data.is_privileged === 1){
                                setAcceptDisabled(false);
                        }
                })
                .catch(err=>console.log("error is",err));
            }
            return stateExceptionList;
          })
        } else if(response.data.status === 301){
          handleLogout();
        } else {
          alert(response.data.message);
        }
      })
      .catch(err=>console.log("error is",err));
    }
  }, [mToken, exceptionInputs]);
  
  return (
    <>
      <Header />
      <main className='application-details'>
        {isLoading && (<div id="loading"></div>)}
        <section>
          <Container fluid>
            <div className='page-title'>
              <Back />
              <h1>New Account Onboarding</h1>
              <Button variant="primary" onClick={() => navigate('/application-details-edit?'+applicationID)} className='ms-auto' disabled={applicationReadOnly}>Edit Information</Button>
            </div>
          </Container>
        </section>
        <section className='bg-gray'>
          <Container fluid>
            <Row>
              <Col>
                <div className='label'>Full Name of the Applicant</div>
                <div className='value'>{appData.firstName} {appData.middleName && appData.middleName} {appData.lastName}</div>
              </Col>
              <Col>
                <div className='label'>Email Address</div>
                <div className='value'>{appDataEmailid}</div>
              </Col>
              <Col>
                <div className='label'>Mobile </div>
                <div className='value'>{appData.countryCode} {appData.mobileNumber}</div>
              </Col>
              <Col>
                <div className='label'>Date of Birth</div>
                <div className='value'>{appData.dateOfBirth}</div>
              </Col>
              <Col>
                <div className='label'>Country of Birth</div>
                <div className='value'>{appData.countryOfBirth}</div>
              </Col>
              <Col>
                <div className='label'>Mother's Maiden Name</div>
                <div className='value'>{appData.mothersMaidenName}</div>
              </Col>
              <Col>
                <div className='label'>Citizenship</div>
                <div className='value'>{appData.citizenship}</div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row>
              <Col className='bdr-right'>
                <div className='label'>Address (Permanent)</div>
                <div className='value'>{appData.addressLine1}, {appData.addressLine2 && appData.addressLine2+', '}{appData.city}, {appData.state}, {appData.country}, {appData.zipcode}</div>
                {appData.placeIdPermanentAddress && <div><a href={"https://www.google.com/maps/search/?api=1&query=Google&query_place_id="+appData.placeIdPermanentAddress} target='blank'>See in map</a></div>}
              </Col>
              <Col className='bdr-right'>
                <div className='label'>Address (Mailing)</div>
                {appData.addressLine1MailingAddress && (<div className='value'>{appData.addressLine1MailingAddress}, {appData.addressLine2MailingAddress && appData.addressLine2MailingAddress+', '}{appData.cityMailingAddress}, {appData.stateMailingAddress}, {appData.countryMailingAddress}, {appData.zipcodeMailingAddress}</div>)}
              </Col>
              <Col>
                <div className='label'>US Address</div>
                {appData.addressLine1US && (<div className='value'>{appData.addressLine1US}, {appData.addressLine2US && appData.addressLine2US+', '}{appData.cityUS}, {appData.stateUS}, {appData.countryUS}, {appData.zipcodeUS}</div>)}
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <h2>Validations</h2>
            <form onSubmit={handleApproveExceptions}>
              <fieldset disabled={applicationReadOnly}>
                <ul className='validation-check mb-3'>
                  {exceptionList &&
                    exceptionList.map((item, index) => {
                      var isDisabled;
                      if(item.passed === 'Y'){
                        isDisabled = true
                      }
                      return (
                      <li key={index} className={item.passed}>
                        <input type="checkbox" className='checkbox' id={item.exception_id} name={item.exception} value={item.description} disabled={isDisabled} onChange={handleChange} />
                        <div>
                          <label htmlFor={item.exception_id}>{item.description}</label>
                          {item.reason && <><br/><b>Reason:</b> {item.reason}</>}
                          {item.passed === 'N' && <div className='reasons d-none'><b>Reason:</b><span className='txt-red'>*</span> <textarea name={item.exception_id} onChange={handleChange} cols="100"></textarea></div>}
                        </div>
                      </li>
                      )})
                  }
                </ul>
                <Button variant="primary" className='btn-manual-override' /* onClick={handleApproveExceptions} */ disabled={approveExceptionDisabled} type="submit">Manual Override</Button>
                <Button variant="transparent" className='ms-3' onClick={handleDisapproveExceptions} disabled={approveExceptionDisabled}>Remove Override</Button>
              </fieldset>
            </form>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row className='align-items-center mb-2'>
              <Col xs="auto"><img src={require('./../assets/images/passport-icon.png')} alt="Passport Icon" /></Col>
              <Col xs="auto">
                <div className='label'>Passport Details</div>
                <div className='value'><span className='value-txt-small'>File uploaded on {formatDateTime(appData.passportImgTimestamp)}</span></div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='d-flex align-items-center'>
                  <img src={'data:image/jpeg;base64,'+appData.passportImg} height="80" alt="Image" className='pp-img' />
                  <span className='ms-3 view' onClick={handleShowPassport}>Click to view</span>
                  <Modal className='show-picture show-pp-picture' show={showPassport} onHide={handleClosePassport} backdrop="static"keyboard={false} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <img src={'data:image/jpeg;base64,'+appData.passportImg} alt="Image" className='deg-90' />
                      <button className='btn-anticlockwise' onClick={rotateAnticlockwise} title="Rotate anti-clockwise"><i className="fas fa-undo"></i></button>
                      <button className='btn-clockwise' onClick={rotateClockwise} title="Rotate clockwise"><i className="fas fa-undo"></i></button>
                    </Modal.Body>
                  </Modal>
                </div>
              </Col>
              {appData.supplementaryPassport && <Col>
                <img src={appData.supplementaryPassport} height="80" alt="Image" />
                <span className='ms-3 view' onClick={() => handleShowSupplementaryFile(appData.supplementaryPassport)}>Click to view</span>
              </Col>}
              <Modal className='show-picture' show={showSupplementaryFile} onHide={handleCloseSupplementaryFile} backdrop="static"keyboard={false} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <img src={supplementaryFile} alt="Image" className='deg-0' />
                  <button className='btn-anticlockwise' onClick={rotateAnticlockwise} title="Rotate anti-clockwise"><i className="fas fa-undo"></i></button>
                  <button className='btn-clockwise' onClick={rotateClockwise} title="Rotate clockwise"><i className="fas fa-undo"></i></button>
                </Modal.Body>
              </Modal>
            </Row>
            <Row className='mb-2'>
              <Col><span className='label'>Name:</span> {docValData.name}</Col>
              <Col><span className='label'>Gender:</span> {docValData.gender}</Col>
              <Col><span className='label'>Nationality:</span> {docValData.Nationality}</Col>
              <Col><span className='label'>Date_of_Birth:</span> {docValData.Date_of_Birth}</Col>
              <Col><span className='label'>Place_of_Birth:</span> {docValData.Place_of_Birth}</Col>
            </Row>
            <Row className='mb-4'>
              <Col><span className='label'>Issue_Date:</span> {docValData.Issue_Date}</Col>
              <Col><span className='label'>IssuingCountry:</span> {docValData.IssuingCountry}</Col>
              <Col><span className='label'>Expiration_Date:</span> {docValData.Expiration_Date}</Col>
              <Col><span className='label'>Issuing_Authority:</span> {docValData.Issuing_Authority}</Col>
              <Col><span className='label'>PlaceofIssue:</span> {docValData.PlaceofIssue}</Col>
            </Row>
            <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>MRZ Data</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col><span className='label'>MRZ_Data:</span> <span dangerouslySetInnerHTML={{__html: docValData.MRZ_Data}} /></Col>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>MRZ_Name:</span> {docValData.MRZ_Name}</Col>
                    <Col><span className='label'>MRZ_Gender:</span> {docValData.MRZ_Gender}</Col>
                    <Col><span className='label'>MRZ_IDNumber:</span> {docValData.MRZ_IDNumber}</Col>
                    <Col><span className='label'>MRZ_ExpiryDate:</span> {docValData.MRZ_ExpiryDate}</Col>
                    <Col><span className='label'>MRZ_DateofBirth:</span> {docValData.MRZ_DateofBirth}</Col>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>MRZ_Nationality:</span> {docValData.MRZ_Nationality}</Col>
                    <Col><span className='label'>MRZ_IssuingCountry:</span> {docValData.MRZ_IssuingCountry}</Col>
                    <Col><span className='label'>MRZ_Detected:</span> {docValData.MRZ_Detected}</Col>
                    <Col><span className='label'>MRZValid:</span> {docValData.MRZValid}</Col>
                    <Col><span className='label'>MRZIDExpired:</span> {docValData.MRZIDExpired}</Col>
                    <Col xs={12} className='mt-2 mb-2'></Col>
                    <Col><span className='label'>MRZDataExpected:</span> {docValData.MRZDataExpected}</Col>
                    <Col><span className='label'>MRZDataExpectedOnBack:</span> {docValData.MRZDataExpectedOnBack}</Col>
                    <Col><span className='label'>MRZDataExpectedOnFront:</span> {docValData.MRZDataExpectedOnFront}</Col>
                    <Col><span className='label'>MRZErrorMessages:</span> {docValData.MRZErrorMessages}</Col>
                    <Col></Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <h6 className='mt-4'>Manually entered information:</h6>
            <Row>
              <Col><span className='label'>Passport ID: </span> {appData.passportNumber}</Col>
              <Col><span className='label'>Issue Date:</span> {appData.IssuanceDate}</Col>
              <Col><span className='label'>Expiry Date:</span> {appData.expirationDate}</Col>
              <Col><span className='label'>Issuing Country:</span> {appData.countryOfIssuance}</Col>
              <Col></Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs="auto"><img src={require('./../assets/images/secondary-doc-icon.png')} alt="Secondary Document Icon" /></Col>
              <Col xs="auto">
                <div className='label'>Secondary Document Details</div>
                <div className='value'><span className='value-txt-small'>File uploaded on {formatDateTime(appData.secondaryDocImgTimestamp)}</span></div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='d-flex align-items-center'>
                  <img src={'data:image/jpeg;base64,'+appData.secondaryDocImg} height="80" alt="Image" />
                  <span className='ms-3 view' onClick={handleShowSecondaryDoc}>Click to view</span>
                  <Modal className='show-picture' show={showSecondaryDoc} onHide={handleCloseSecondaryDoc} backdrop="static"keyboard={false} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <img src={'data:image/jpeg;base64,'+appData.secondaryDocImg} alt="Image" className='deg-0' />
                      <button className='btn-anticlockwise' onClick={rotateAnticlockwise} title="Rotate anti-clockwise"><i className="fas fa-undo"></i></button>
                      <button className='btn-clockwise' onClick={rotateClockwise} title="Rotate clockwise"><i className="fas fa-undo"></i></button>
                    </Modal.Body>
                  </Modal>
                </div>
              </Col>
              {appData.supplementarySecondaryDoc && <Col>
                <img src={appData.supplementarySecondaryDoc} height="80" alt="Image" />
                <span className='ms-3 view' onClick={() => handleShowSupplementaryFile(appData.supplementarySecondaryDoc)}>Click to view</span>
              </Col>}
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs="auto"><img src={require('./../assets/images/selfie-icon.png')} alt="Selfie Icon" /></Col>
              <Col xs="auto">
                <div className='label'>Selfie Picture</div>
                <div className='value'><span className='value-txt-small'>File uploaded on {formatDateTime(appData.selfieImgTimestamp)}</span></div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='d-flex align-items-center'>
                  <img src={'data:image/jpeg;base64,'+appData.selfieImg} height="80" alt="Image" />
                  <span className='ms-3 view' onClick={handleShowSelfiePicture}>Click to view</span>
                  <Modal className='show-picture' show={showSelfiePicture} onHide={handleCloseSelfiePicture} backdrop="static"keyboard={false} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <img src={'data:image/jpeg;base64,'+appData.selfieImg} alt="Image" className='deg-0' />
                      <button className='btn-anticlockwise' onClick={rotateAnticlockwise} title="Rotate anti-clockwise"><i className="fas fa-undo"></i></button>
                      <button className='btn-clockwise' onClick={rotateClockwise} title="Rotate clockwise"><i className="fas fa-undo"></i></button>
                    </Modal.Body>
                  </Modal>
                </div>
              </Col>
              {appData.supplementarySelfie && <Col>
                <img src={appData.supplementarySelfie} height="80" alt="Image" />
                <span className='ms-3 view' onClick={() => handleShowSupplementaryFile(appData.supplementarySelfie)}>Click to view</span>
              </Col>}
            </Row>
          </Container>
        </section>
        <section className='bg-gray'>
          <Container fluid>
            <div className='d-flex align-items-center mb-3'>
              <div className='label'><img src={require('./../assets/images/instructions-icon.png')} alt="Passport Icon" className='me-3' /> W8-BEN Details</div>
              {appData['w8ben-filled-form'] && <><div className='ms-3'>
                <button type="button" className='view-file' title="View W8-BEN Filled Form" onClick={() => showFile(appData['w8ben-filled-form'])}><img src={require('./../assets/images/button-icon.png')} /></button><button type="button" className='download-file' title="Download W8-BEN Filled Form" onClick={() => downloadFile(appData['w8ben-filled-form'], 'w8ben-filled-form-'+applicationID)}><i className="fas fa-file-download"></i></button>
                <Modal className='show-picture' show={showFileModal} onHide={() => {setShowFileModal(false); setFile('');}} backdrop="static" keyboard={false} centered>
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    {file && <embed src={file} width={file.startsWith("data:image") ? '' : '100%'} height="600" />}
                  </Modal.Body>
                </Modal>
              </div></>}
            </div>
            <Row>
              <Col>
                <div className='label'>U.S. Taxpayer Identification Number (SSN or ITIN)</div>
                <div className='value'>{appData.w8ben_USTaxPayerId}</div>
              </Col>
              <Col>
                <div className='label'>Foreign Tax Identification Number</div>
                <div className='value'>{appData.w8ben_foreignTaxPayer}</div>
              </Col>
              <Col>
                <div className='label'>FTIN (Foreign Tax Identification Number) not legally required</div>
                <div className='value'>{appData.w8ben_FtinNotReq}</div>
              </Col>
              <Col>
                <div className='label'>Reference number(s)</div>
                <div className='value'>{appData.w8ben_referenceNumber}</div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bg-gray'>
          <Container fluid>
            <h6>Special rates and conditions: (if applicable)</h6>
            <Row>
              <Col>
                <div className='label'>Article and paragraph</div>
                <div className='value'>{appData.w8ben_article}</div>
              </Col>
              <Col>
                <div className='label'>% rate</div>
                <div className='value'>{appData.w8ben_rate}</div>
              </Col>
              <Col>
                <div className='label'>Type of Income</div>
                <div className='value'>{appData.w8ben_typeOfIncome}</div>
              </Col>
              <Col>
                <div className='label'>Additional Conditions</div>
                <div className='value'>{appData.w8ben_additionalConditions}</div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bg-gray bdr-btm'>
          <Container fluid>
            <h6>Additional information:</h6>
            <Row>
              <Col>
                <div className='label'>Politically exposed</div>
                <div className='value'>{appData.pep === 'Yes' ? (<span className='txt-red'>{appData.pep}</span>): appData.pep}</div>
              </Col>
              <Col>
                <div className='label'>Employment Status</div>
                <div className='value'>{appData.employmentStatus}</div>
              </Col>
              <Col>
                <div className='label'>Occupation</div>
                <div className='value'>{appData.occupation}</div>
              </Col>
              <Col>
                <div className='label'>Company Name</div>
                <div className='value'>{appData.companyName}</div>
              </Col>
              <Col xs="auto">
                <div className='label'>Address</div>
                <div className='value'>{appData.addressLine1W8ben1 && appData.addressLine1W8ben1+', '}{appData.addressLine2W8ben1 && appData.addressLine2W8ben1+', '}{appData.cityW8ben1 && appData.cityW8ben1+', '}{appData.stateW8ben1 && appData.stateW8ben1+', '}{appData.countryW8ben1 && appData.countryW8ben1+', '}{appData.zipcodeW8ben1}</div>
              </Col>
              <Col>
                <div className='label'>Annual Household Income</div>
                <div className='value'>$ {appData.annualIncome}</div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bg-gray bdr-btm'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col xs="auto"><img src={require('./../assets/images/secondary-doc-icon.png')} alt="Secondary Document Icon" /></Col>
              <Col xs="auto">
                <div className='label'>Foreign Tax Identification Number</div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='d-flex align-items-center'>
                  <img src={'data:image/jpeg;base64,'+appData.ftinId} height="80" alt="Image" />
                  <span className='ms-3 view' onClick={handleShowFtinId}>Click to view</span>
                  <Modal className='show-picture' show={showFtinId} onHide={handleCloseFtinId} backdrop="static"keyboard={false} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                      <img src={'data:image/jpeg;base64,'+appData.ftinId} alt="Image" className='deg-0' />
                      <button className='btn-anticlockwise' onClick={rotateAnticlockwise} title="Rotate anti-clockwise"><i className="fas fa-undo"></i></button>
                      <button className='btn-clockwise' onClick={rotateClockwise} title="Rotate clockwise"><i className="fas fa-undo"></i></button>
                    </Modal.Body>
                  </Modal>
                </div>
              </Col>
              {appData.supplementaryFTIN && <Col>
                <img src={appData.supplementaryFTIN} height="80" alt="Image" />
                <span className='ms-3 view' onClick={() => handleShowSupplementaryFile(appData.supplementaryFTIN)}>Click to view</span>
              </Col>}
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row>
              <Col xs="auto">
                <div className='label'>Source of Income</div>
                <div className='value'>{appData.sourceOfIncome}</div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='label'>Other Sources</div>
                <div className='value'>{appData.otherSources}</div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row>
              <Col xs="auto">
                <div className='label'>Account Usage</div>
                <div className='value'>{appData.useAccountFor}</div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='label'>Source of initial deposit</div>
                <div className='value'>{appData.sourceOfInitialDeposit}</div>
              </Col>
              <Col xs={{span:"auto", offset:1}}>
                <div className='label'>Other Sources</div>
                <div className='value'>{appData.otherSourceOfInitialDeposit}</div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col className='bdr-right'>
                <div className='label pt-2'>No. of Incoming wires</div>
                <div className='value'>{appData.incomingWiresCount}</div>
                <div className='label'>Expected amount</div>
                <div className='value'>{appData.incomingWiresAmt}</div>
              </Col>
              <Col className='bdr-right ps-5'>
                <div className='label pt-2'>No. of outgoing wires</div>
                <div className='value'>{appData.outgoingWiresCount}</div>
                <div className='label'>Expected amount</div>
                <div className='value'>{appData.outgoingWiresAmt}</div>
              </Col>
              <Col className='bdr-right ps-5'>
                <div className='label pt-2'>No. of ACH Credits</div>
                <div className='value'>{appData.achDepositCount}</div>
                <div className='label'>Expected amount</div>
                <div className='value'>{appData.achDepositAmt}</div>
              </Col>
              <Col className='ps-5'>
                <div className='label pt-2'>No. of ACH Debits</div>
                <div className='value'>{appData.achDebitsCount}</div>
                <div className='label'>Expected amount</div>
                <div className='value'>{appData.achDebitsAmt}</div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bdr-btm'>
          <Container fluid>
            <Row className='align-items-center'>
              <Col className='bdr-right'>
                <div className='label pt-2'>No. of Check Deposits</div>
                <div className='value'>{appData.atmDepositsCount}</div>
                <div className='label'>Expected amount</div>
                <div className='value'>{appData.atmDepositsAmt}</div>
              </Col>
              <Col className='bdr-right ps-5'>
                <div className='label pt-2'>No. of Check Transactions</div>
                <div className='value'>{appData.atmWithdrawalsCount}</div>
                <div className='label'>Expected amount</div>
                <div className='value'>{appData.atmWithdrawalsAmt}</div>
              </Col>
              <Col className='bdr-right ps-5'>
                <div className='label pt-2'>No. of Cash Deposits</div>
                <div className='value'>{appData.cashDepositsCount}</div>
                <div className='label'>Expected amount</div>
                <div className='value'>{appData.cashDepositsAmt}</div>
              </Col>
              <Col className='ps-5'>
                <div className='label pt-2'>No. of Cash Withdrawals</div>
                <div className='value'>{appData.cashWithdrawalsCount}</div>
                <div className='label'>Expected amount</div>
                <div className='value'>{appData.cashWithdrawalsAmt}</div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='bg-gray'>
          <Container fluid>
            <h2><img src={require('./../assets/images/agreements-icon.png')} alt="Agreements Icon" className='me-4' />Agreements &amp; Disclosures</h2>
            <Row>
              <Col>
                <ul>
                  <li><img src={require('./../assets/images/check-icon.png')} alt="Check Icon" className='me-2' />Agrees to Electronic Communication disclosure and consent</li>
                </ul>
              </Col>
              <Col>
                <ul>
                  <li><img src={require('./../assets/images/check-icon.png')} alt="Check Icon" className='me-2' />General Account Disclosure (includes Funds Availability Policy and EFT Disclosure)</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='pt-4 pb-3 bdr-btm'>
          <Container fluid>
            <Row>
              <Col xs="auto">
                <h2>User experience rating with us</h2>
              </Col>
              <Col>
                <div id="star-rating" className="star-rating">
                  {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                      <Button variant="star" type="button" key={index} className={index <= appData.rating ? "on" : "off"}></Button>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className='pt-4 pb-3 bdr-btm'>
          <Container fluid>
            <Row>
              <Col xs="auto">
                <h2>Time spent in the portal</h2>
              </Col>
              <Col>
                {timeSpentOnPortal}
              </Col>
            </Row>
          </Container>
        </section>
        <section className='pt-5 pb-5'>
          <Container fluid>
            <Button variant="transparent" onClick={() => navigate('/')}>Cancel</Button>
            <Button variant="primary" className='ms-3' onClick={handleShowConfirmationDenial} disabled={applicationReadOnly ? applicationReadOnly : !denyApplicationEnabled}>Not Accept</Button>
            <Button variant="primary" className='ms-3' onClick={handleShowConfirmation} disabled={applicationReadOnly ? applicationReadOnly : acceptDisabled}>Accept</Button>
            <Button variant="primary" className='ms-3' onClick={handleShowInvite} disabled={applicationReadOnly ? applicationReadOnly : sendInviteDisabled}>Re-invite</Button>
            <span className='float-end'>Last Updated: {lastUpdated}</span>

            <Modal show={showConfirmation} onHide={handleCloseConfirmation} backdrop="static"keyboard={false} centered className='content-text'>
              <Modal.Header closeButton>
                <Modal.Title><img src={require('./../assets/images/confirmation-icon.png')} alt="Confirmation Icon" className='me-3' />Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Are you sure you want to accept the New Account application for</p>
                <p>{appData.firstName} {appData.middleName ? appData.middleName+' ' : null}{appData.lastName}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleCloseConfirmation}>No</Button>
                <Button variant="primary" className='ms-3' onClick={handleApproveYes}>Yes</Button>
              </Modal.Footer>
            </Modal>
            
            <Modal show={showConfirmationDenial} onHide={handleCloseConfirmationDenial} backdrop="static"keyboard={false} centered className='content-text'>
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title><img src={require('./../assets/images/denial-icon.png')} alt="Denial Icon" className='me-3' />Not Accept Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Are you sure you dont' want to accept the New Account application for</p>
                  <p>{appData.firstName} {appData.middleName ? appData.middleName+' ' : null}{appData.lastName}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="transparent" onClick={handleCloseConfirmationDenial}>No</Button>
                  <Button variant="primary" className='ms-3' onClick={handleDenyYes}>Yes</Button>
                </Modal.Footer>
              </Form>
            </Modal>

            <Modal show={showInvite} onHide={handleCloseInvite} backdrop="static"keyboard={false} centered className='content-text'>
              <Modal.Header closeButton>
                <Modal.Title><img src={require('./../assets/images/sent-invite-icon.png')} alt="sent-invite-icon Icon" className='me-3' />Resend Invite</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Are you sure you want to resend invite to</p>
                <p>{appData.firstName} {appData.middleName ? appData.middleName+' ' : null}{appData.lastName}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleCloseInvite}>No</Button>
                <Button variant="primary" className='ms-3' onClick={handleReInvite}>Yes</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showError} onHide={handleCloseError} backdrop="static"keyboard={false} centered className='content-text'>
              <Modal.Body>
                <p>{errorMsg}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="transparent" onClick={handleCloseError}>OK</Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </section>
      </main>
    </>
  );
}

export default ApplicationDetails;
